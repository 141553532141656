<template>
  <div class="b-modal">
    <div id="stl_cont" class="stl-preview"></div>
    <div class="c-modal-footer">
      <div class="checkbox-group">
        <input
          type="radio"
          v-model="selectedJaw"
          name="jaw-selection"
          value="both"
          class="checkbox-input"
          @change="showJaw"
          id="jaws"
        />
        <label for="jaws" class="checkbox-label">
          {{ $t("Show Both Jaws") }}
        </label>
      </div>
      <div class="checkbox-group">
        <input
          type="radio"
          class="checkbox-input"
          id="upper"
          v-model="selectedJaw"
          name="jaw-selection"
          value="upper"
          @change="showJaw"
        />
        <label for="upper" class="checkbox-label">
          {{ $t("Show Upper Jaw") }}
        </label>
      </div>
      <div class="checkbox-group">
        <input
          type="radio"
          class="checkbox-input"
          id="lower"
          v-model="selectedJaw"
          name="jaw-selection"
          value="lower"
          @change="showJaw"
        />
        <label for="lower" class="checkbox-label">
          {{ $t("Show Lower Jaw") }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import ElementIcon from "@/components/elements/Icon.vue";
import { BLink, BRow, BCol, BImg, BPagination } from "bootstrap-vue";
import { StlViewer } from "@/libs/3dviewer/stl_viewer.min.js";
import NProgress from "nprogress";

export default {
  components: {
    ElementIcon,
    BRow,
    BCol,
    BLink,
    BImg,
    BPagination,
  },
  props: {
    upper: {
      type: String,
      default: null,
    },
    lower: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedJaw: "both",
      fileServiceBaseUrl: "",
    };
  },
  methods: {
    showJaw: function () {
      if (this.selectedJaw == "both") {
        this.stl_viewer.set_opacity(1, 1);
        this.stl_viewer.set_opacity(2, 1);
      } else if (this.selectedJaw == "upper") {
        this.stl_viewer.set_opacity(1, 1);
        this.stl_viewer.set_opacity(2, 0);
      } else {
        this.stl_viewer.set_opacity(1, 0);
        this.stl_viewer.set_opacity(2, 1);
      }
    },

    uploadDone: function () {
      NProgress.done();
      this.loadingDone = true;
    },
    uploadProgress: function (load_status, load_session) {
      this.loadingDone = false;
      var sumLoading = 0;
      var allToLoad = 0;
      for (var i = 0; i < load_status.length; i++) {
        if (
          typeof load_status[i] === "undefined" ||
          typeof load_status[i]["total"] === "undefined"
        ) {
          continue;
        }
        allToLoad += load_status[i]["total"];
        sumLoading += load_status[i]["loaded"];
      }

      var percentage = sumLoading / allToLoad;
      console.log(percentage);
      if (percentage > 0.8) {
        NProgress.set(0.6);
        // setTimeout(this.keepProgressbarBusy.bind(this), 1100);
      } else {
        NProgress.set(percentage);
      }
    },
  },
  mounted() {
    this.fileServiceBaseUrl = process.env.VUE_APP_FILESERVICE_BASEURL;
    this.stl_viewer = new StlViewer(document.getElementById("stl_cont"), {
      all_loaded_callback: this.uploadDone.bind(this),
      loading_progress_callback: this.uploadProgress.bind(this),
      center_models: false,
      auto_rotate: true,
      controls: 1,
      allow_drag_and_drop: false,
    });

    // upper
    if (this.upper != null) {
      this.stl_viewer.add_model({
        id: 1,
        temp_filename: "mesh1.obj",
        filename: this.fileServiceBaseUrl + "/files/" + this.upper,
        color: "#FFDDFF",
      });
    }

    if (this.lower != null) {
      this.stl_viewer.add_model({
        id: 2,
        temp_filename: "mesh1.obj",
        filename: this.fileServiceBaseUrl + "/files/" + this.lower,
        color: "#DDFFFF",
      });
    }
  },
};
</script>
